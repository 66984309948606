import * as echarts from "echarts";
export const option = {
  title: {
    text: "",
    left: "center",
    textStyle: {
      fontSize: 18
    }
  },
  backgroundColor: "#fff",
  grid: {
    top: "22%",
    bottom: "20%",
    right: "10%",
    left: "10%"
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: "#fff",
    textStyle: {
      color: "#5c6c7c"
    },
    axisPointer: {
      lineStyle: {
        color: "transparent"
      }
    },
    formatter:
      "<div style='font-weight: bold;color: #333;margin-bottom: 10px'>{b}</div><span style='color: #5B5C68'>{a0}:</sapn> <span style='color: #1181FA'>{c0}</sapn><br /><span style='color: #5B5C68'>{a1}:</sapn> <span style='color: #FFBA01'>{c1}</sapn>",
    padding: [10, 20],
    extraCssText: "box-shadow: 0px 0px 29px 0px rgba(10, 89, 218, 0.08);",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderColor: "transparent"
  },
  legend: {
    data: ["GDP（亿元）", "增速（%）"],
    top: "10%",
    left: "center",
    textStyle: {
      color: "#333"
    }
  },
  dataZoom: [
    {
      type: "slider",
      bottom: "10",
      show: true,
      xAxisIndex: [0],
      start: 0,
      end: 100,
      height: 10,
      textStyle: {
        color: "#ccd7d7"
      }
    }
  ],

  xAxis: {
    data: [],
    axisLine: {
      show: true,
      lineStyle: {
        color: "#F3F5F7"
      }
    },
    axisTick: {
      show: true
    },
    axisLabel: {
      show: true,
      rotate: 30,
      textStyle: {
        color: "#5e6266"
      }
    }
  },
  yAxis: [
    {
      type: "value",
      name: "（亿元）",
      nameTextStyle: {
        color: "#5e6266",
        padding: [0, 0, 6, -54]
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#F3F5F7"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#5e6266"
        }
      }
    },
    {
      type: "value",
      name: "（%）",
      nameTextStyle: {
        color: "#5e6266",
        padding: [0, 0, 6, 30]
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: true
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "#F3F5F7"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#5e6266"
        }
      }
    }
  ],
  series: [
    {
      name: "GDP（亿元）",
      type: "bar",
      barWidth: 10,
      itemStyle: {
        color: "#1181fa"
      },
      data: []
    },
    {
      name: "增速（%）",
      yAxisIndex: 1,
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 10,
      itemStyle: {
        color: "#ffba01"
      },
      lineStyle: {
        type: "dashed",
        color: "#ffba01",
        width: 3
      },
      data: []
    }
  ]
};
export const curveOption = {
  title: {
    text: "",
    left: "center",
    textStyle: {
      fontSize: 18
    }
  },
  backgroundColor: "#fff",
  grid: {
    top: "16%",
    bottom: "20%",
    right: "10%",
    left: "10%"
  },
  tooltip: {
    trigger: "axis",
    backgroundColor: "#fff",
    textStyle: {
      color: "#5c6c7c"
    },
    axisPointer: {
      lineStyle: {
        color: "transparent"
      }
    },
    formatter:
      "<div style='font-weight: bold;color: #333;margin-bottom: 10px'>{b}</div><span style='color: #5B5C68'>增速（%）:</sapn> <span style='color: #1181fa'>{c0}</sapn>",
    padding: [10, 20],
    extraCssText: "box-shadow: 0px 0px 29px 0px rgba(10, 89, 218, 0.08);",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderColor: "transparent"
  },
  dataZoom: [
    {
      type: "slider",
      bottom: "10",
      show: true,
      xAxisIndex: [0],
      start: 0,
      end: 100,
      height: 10,
      textStyle: {
        color: "#ccd7d7"
      }
    }
  ],

  xAxis: {
    data: [],
    axisLine: {
      show: true,
      lineStyle: {
        color: "#F3F5F7"
      }
    },
    axisTick: {
      show: true
    },
    axisLabel: {
      show: true,
      rotate: 30,
      textStyle: {
        color: "#5e6266"
      }
    }
  },
  yAxis: [
    {
      type: "value",
      name: "（%）",
      nameTextStyle: {
        color: "#5e6266",
        padding: [0, 0, 6, -30]
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#F3F5F7"
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#5e6266"
        }
      }
    }
  ],
  series: [
    {
      name: "GDP（亿元）",
      type: "bar",
      barWidth: 10,
      itemStyle: {
        color: "#1181fa"
      },
      data: []
    }
  ]
};
