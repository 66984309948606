<template>
	<div class="economic-data">
		<div class="economic-main">
			<div class="title-box">
				<div class="title">经济及行业数据</div>
			</div>
			<div class="nav-box">
				<div class="nav-list">
					<div class="nav-item" :class="navIndex == 1 ? 'nav-item-select' : ''" @click="clickNav(1)">按地区</div>
					<div class="nav-item" :class="navIndex == 2 ? 'nav-item-select' : ''" @click="clickNav(2)">按年份</div>
					<div class="nav-item" :class="navIndex == 3 ? 'nav-item-select' : ''" @click="clickNav(3)">按指标</div>
					<div class="nav-item" :class="navIndex == 4 ? 'nav-item-select' : ''" @click="clickNav(4)"
						v-if="echartsShow">图表</div>
				</div>
				<div class="icon-box">
					<div class="download-box" @click="downloadTable" v-if="navIndex != 4&&downloadButtonShow">
						<span class="iconfont icon-download"></span>
						<span>{{"下载表格数据" }}</span>
					</div>
					<div class="download-box" @click="downloadTable" v-if="navIndex == 4&&downloadButtonShowChart">
						<span class="iconfont icon-download"></span>
						<span>{{"下载图表"}}</span>
					</div>
					<div class="note" v-show="navIndex == 4&&downloadButtonShowChart">
						<span class="iconfont icon-zhushibiaozhui"></span>
						<div class="note-title animated fadeIn">
							<span>只下载图表展示区域的部分</span>
						</div>
					</div>
				</div>
			</div>
			<div class="main-box">
				<div class="left-screen">
					<div class="left-top">
						<div class="left-item">
							<div class="item-name">年份</div>
							<div class="date-picker" v-show="navIndex != 4">
								<el-select v-model="conPO.year" multiple collapse-tags placeholder="请选择年份" clearable
									ref="year">
									<el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
								</el-select>
							</div>
							<div class="date-picker" v-show="navIndex == 4">
								<el-select v-model="chartPO.year" multiple collapse-tags placeholder="请选择年份" clearable
									ref="year">
									<el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
								</el-select>
							</div>
						</div>
						<div class="left-item">
							<div class="item-name">地区</div>
							<div class="area-picker" v-show="navIndex != 4">
								<select-area ref="selectArea" :all="true" :key="selectAreaKey"></select-area>
							</div>
							<div class="area-picker" v-show="navIndex == 4">
								<Selectchart-area ref="selectchartArea" :all="true"
									:key="selectchartAreaKey"></Selectchart-area>
							</div>
						</div>
						<div class="indicator-item">
							<div class="item-name">指标</div>
							<div class="indicator-picker" v-show="navIndex != 4">
								<el-tree ref="indicatorTree" :data="indicatorList" show-checkbox node-key="quotaCode"
									check-strictly default-expand-all :props="indicatorProps">
									<template slot-scope="{node}">
										<el-tooltip effect="light" :content="node.label" placement="bottom"
											style="font-size: 14px">
											<span>{{ node.label }}</span>
										</el-tooltip>
									</template>
								</el-tree>
							</div>
							<div class="indicator-picker" v-show="navIndex == 4">
								<el-tree ref="tree" :data="indicatorChartList" show-checkbox node-key="quotaCode"
									check-strictly default-expand-all :props="indicatorProps" @check="treeNodeClick"
									:default-checked-keys="defaultkeys">
									<template slot-scope="{node}">
										<el-tooltip effect="light" :content="node.label" placement="bottom"
											style="font-size: 14px">
											<span>{{ node.label }}</span>
										</el-tooltip>
									</template>
								</el-tree>
							</div>
						</div>
					</div>
					<div class="left-bottom">
						<el-button type="primary" @click="handleQuery">搜索</el-button>
						<el-button @click="resetQuery">重置</el-button>
					</div>
				</div>
				<div class="table-box" v-if="navIndex == 1">
					<div class="empty-box" v-show="areaTableData.length == 0">
						<empty></empty>
					</div>
					<div id="tableArea" style="width: 100%">
						<el-table v-show="areaTableData.length != 0" :data="areaTableData" style="width: 100%"
							header-cell-class-name="table_header" :cell-style="{ 'text-align': 'center' }"
							v-table-move="['table']">
							<!-- <el-table-column type="selection" align="center" width="55" fixed>
            </el-table-column> -->
							<el-table-column width="135" prop="year" fixed>
								<template slot="header">
									<div class="left-header">
										<span class="name-item">地区</span>
										<span class="name-item">指标</span>
										<span class="name-item">年份</span>
										<span class="line"></span>
										<span class="line-top"></span>
									</div>
								</template>
							</el-table-column>
							<el-table-column v-for="(item, i) in areaHeaderData" :key="i" :label="getAreaName(item.area)"
								align="center">
								<template slot="header" slot-scope="scope">
									<div style="margin: 20px 0">
										<div>{{ scope.column.label }}</div>
									</div>
								</template>
								<el-table-column v-for="(key, j) in item.children" :key="j" :prop="`indicator${i}${j}`"
									:label="key.quota" width="224" sortable>
									<template slot="header" slot-scope="scope">
										<div class="indicator-header">
											<el-tooltip effect="light" :content="scope.column.label" placement="bottom">
												<div class="text-ellipsis" style="width: 150px; text-align: center">
													{{ scope.column.label }}
													<i class="iconfont icon-jiangxu" style="color: #333"
														v-if="!scope.column.order"></i>
													<i class="iconfont icon-jiangxu" style="color: #1181fa"
														v-if="scope.column.order == 'descending'"></i>
													<i class="iconfont icon-shengxu" style="color: #1181fa"
														v-if="scope.column.order == 'ascending'"></i>
												</div>
											</el-tooltip>

											<el-tooltip effect="light" :content="key.dimension || '-'" placement="bottom">
												<span class="iconfont icon-tishi"></span>
											</el-tooltip>
											<span class="iconfont icon-close"
												@click.stop="areaHandleDelete(scope.column.label)"></span>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="table-box" v-if="navIndex == 2">
					<div class="empty-box" v-show="yearTableData.length == 0">
						<empty></empty>
					</div>
					<div id="tableYear" style="width: 100%">
						<el-table v-show="yearTableData.length != 0" :data="yearTableData" style="width: 100%"
							header-cell-class-name="table_header" :cell-style="{ 'text-align': 'center' }"
							v-table-move="['table']">
							<!-- <el-table-column type="selection" align="center" width="55" fixed>
            </el-table-column> -->
							<el-table-column width="135" prop="area" fixed>
								<template slot="header">
									<div class="left-header">
										<span class="name-item">年份</span>
										<span class="name-item">指标</span>
										<span class="name-item">地区</span>
										<span class="line"></span>
										<span class="line-top"></span>
									</div>
								</template>
							</el-table-column>
							<el-table-column v-for="(item, i) in yearHeaderData" :key="i" :label="item.year" align="center">
								<template slot="header" slot-scope="scope">
									<div style="margin: 20px 0">
										<div>{{ scope.column.label }}</div>
									</div>
								</template>
								<el-table-column v-for="(key, j) in item.children" :key="j" :prop="`indicator${i}${j}`"
									:label="key.quota" width="224" sortable>
									<template slot="header" slot-scope="scope">
										<div class="indicator-header">
											<el-tooltip effect="light" :content="scope.column.label" placement="bottom">
												<div class="text-ellipsis" style="width: 150px; text-align: center">
													{{ scope.column.label }}
													<i class="iconfont icon-jiangxu" style="color: #333"
														v-if="!scope.column.order"></i>
													<i class="iconfont icon-jiangxu" style="color: #1181fa"
														v-if="scope.column.order == 'descending'"></i>
													<i class="iconfont icon-shengxu" style="color: #1181fa"
														v-if="scope.column.order == 'ascending'"></i>
												</div>
											</el-tooltip>
											<el-tooltip effect="light" :content="key.dimension || '-'" placement="bottom">
												<span class="iconfont icon-tishi"></span>
											</el-tooltip>
											<span class="iconfont icon-close"
												@click.stop="yearHandleDelete(scope.column.label)"></span>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="table-box" v-if="navIndex == 3">
					<div class="empty-box" v-show="indicatorTableData.length == 0">
						<empty></empty>
					</div>
					<div id="tableIndicator" style="width: 100%">
						<el-table v-show="indicatorTableData.length != 0" :data="indicatorTableData" style="width: 100%"
							header-cell-class-name="table_header" :cell-style="{ 'text-align': 'center' }"
							v-table-move="['table']">
							<!-- <el-table-column type="selection" align="center" width="55" fixed>
            </el-table-column> -->
							<el-table-column width="135" prop="year" fixed>
								<template slot="header">
									<div class="left-header">
										<span class="name-item">指标</span>
										<span class="name-item">地区</span>
										<span class="name-item">年份</span>
										<span class="line"></span>
										<span class="line-top"></span>
									</div>
								</template>
							</el-table-column>
							<el-table-column v-for="(item, i) in indicatorHeaderData" :key="i" :label="item.quota"
								align="center">
								<template slot="header" slot-scope="scope">
									<!-- <div> -->
									<el-tooltip effect="light" :content="scope.column.label" placement="bottom">
										<div class="text-ellipsis" style="margin: 20px 0; width: 200px; text-align: center">
											{{ scope.column.label }}
										</div>
									</el-tooltip>

									<!-- </div> -->
								</template>
								<el-table-column v-for="(key, j) in item.children" :key="j" :prop="`indicator${i}${j}`"
									:label="getAreaName(key.area)" width="224" sortable>
									<template slot="header" slot-scope="scope">
										<div class="indicator-header">
											<el-tooltip effect="light" :content="scope.column.label" placement="bottom">
												<div class="text-ellipsis" style="width: 150px; text-align: center">
													{{ scope.column.label }}
													<i class="iconfont icon-jiangxu" style="color: #333"
														v-if="!scope.column.order"></i>
													<i class="iconfont icon-jiangxu" style="color: #1181fa"
														v-if="scope.column.order == 'descending'"></i>
													<i class="iconfont icon-shengxu" style="color: #1181fa"
														v-if="scope.column.order == 'ascending'"></i>
												</div>
											</el-tooltip>
											<el-tooltip effect="light" :content="key.dimension || '-'" placement="bottom">
												<span class="iconfont icon-tishi"></span>
											</el-tooltip>
											<span class="iconfont icon-close"
												@click.stop="indicatorHandleDelete(scope.column.label)"></span>
										</div>
									</template>
								</el-table-column>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="echarts-box" id="echarts-box" v-show="navIndex == 4">
					<div class="chart-box" :id="'chart' + i" v-for="(item, i) in chartData" :key="i"></div>
				</div>
			</div>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import axios from "axios";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import Empty from "@/components/Empty";
import html2canvas from "html2canvas";
import SelectArea from "@/components/SelectArea";
import SelectchartArea from "./SelectchartArea/index.vue";
import * as echarts from "echarts";
import { option, curveOption } from "./chartsConfig";
import ToPayOrMember from "@/components/ToPayOrMember"
export default {
	components: {
		LoginModal,
		MemberModal,
		Empty,
		SelectArea,
		SelectchartArea,
		ToPayOrMember
	},
	data() {
		return {
			showPayBtn: false,
			text: "",
			echartsShow: false,
			navIndex: 1,
			conPO: {
				year: [],
				area: [],
			},
			yearList: [],
			areaList: [],
			indicatorProps: {
				label: "quotaName",
				children: "childrenList",
			},
			indicatorList: [], // 指标数据
			tableData: [], // 接口返回的表格数据
			areaHeaderData: [], // 表头数据-按地区
			yearHeaderData: [], // 表头数据-按年份
			indicatorHeaderData: [], // 表头数据-按指标
			areaTableData: [], // 渲染表格数据-按地区
			yearTableData: [], // 渲染表格数据-按年份
			indicatorTableData: [], // 渲染表格数据-按指标
			selectAreaKey: "",
			selectchartAreaKey: "",
			indicatorChartList: [
				{
					quotaCode: "10",
					quotaName: "经济",
					childrenList: [
						{
							quotaCode: "1",
							quotaName: "GDP及增速（亿元）",
						},
					],
				},
				{
					quotaCode: "11",
					quotaName: "财政",
					childrenList: [
						{
							quotaCode: "2",
							quotaName: "一般公共预算收入及债务负担水平人口",
						},
					],
				},
				{
					quotaCode: "12",
					quotaName: "人口",
					childrenList: [
						{
							quotaCode: "3",
							quotaName: "人口数量及城镇化率",
						},
					],
				},
				{
					quotaCode: "13",
					quotaName: "固定资产投资",
					childrenList: [
						{
							quotaCode: "4",
							quotaName: "固定资产投资增速（%）",
						},
					],
				},
				{
					quotaCode: "14",
					quotaName: "建筑业",
					childrenList: [
						{
							quotaCode: "5",
							quotaName: "建筑业增加值及GDP占比（%）",
						},
					],
				},
			],
			defaultkeys: [1],
			chartPO: {
				year: [],
			},
			currentCheckData: { quotaCode: "1" }, // 当前选中的值
			chartData: [],
			memberId:null,
			downloadButtonShow:null,
			downloadButtonShowChart:null
		};
	},
	mounted() {
		window.addEventListener("scroll", this.scrollEvent); // 监听页面滚动
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011010",
		});
		if (this.$session.getUsers()) {
			this.echartsShow = true;
		}
		this.getYearList();
		// 获取省市区,经济数据库指标-按地区
		axios
			.all([this.$api.industry.getAreaList(), this.$api.industry.getSystemQuota()])
			.then(
				axios.spread((area, indicator) => {
					let areaList = area.data;
					areaList.forEach(item => {
						item.childrenList.forEach(key => {
							delete key.childrenList;
						});
					});
					for (let i = 0; i < areaList.length; i++) {
						areaList[i].type = "省";
						for (let j = 0; j < areaList[i].childrenList.length; j++) {
							areaList[i].childrenList[j].type = "市";
						}
					}
					this.areaList = areaList;
					this.$refs.selectArea.areaList = areaList;
					this.$refs.selectchartArea.areaList = [
						...areaList.map(item => {
							return Object.assign({}, item);
						}),
					];
					this.indicatorList = indicator.data;
					this.getEconomicDatabaseByArea("init");
				})
			)
			.catch(msg => {
				if (msg?.msg) {
					this.$message.error(msg?.msg);
				}
			});
		// this.chartPO.year = [this.yearList[0]];
		this.memberId=this.$session.getUsers().memberId
		this.getDownloadButtonShow();
		this.getdownloadButtonShowChart();
	},
	methods: {
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode:10031001 ,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow=="10011001"?true:false;
			});
		},
		// 获取下载按钮是否显示  经济数据库图表
		getdownloadButtonShowChart() {
			let params = {
				childModuleCode:10031007 ,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShowChart = res.data.downloadButtonShow=="10011001"?true:false;
			});
		},
		scrollEvent() {
			this.$refs.year.visible = false;
			this.$refs.selectArea.open = false;
		},
		downloadTable() {
			if (this.$session.getUsers()) {
				if (this.navIndex != 4) {
					let quotaList = this.$refs.indicatorTree.getCheckedKeys();
					let selectList = this.$refs.selectArea.selectList;
					// let { conPO, navIndex } = this;
					let { conPO } = this;
					if (quotaList.length == 0 || conPO.year.length == 0 || selectList.length == 0) {
						this.$message.error("请搜索后再下载");
						return;
					}
					let provinceList = [];
					let cityList = [];
					if (selectList.length != 0) {
						selectList.forEach(item => {
							if (item.type == "省") {
								provinceList.push(item.code);
							} else if (item.type == "市") {
								cityList.push(item.code);
							}
						});
					}
					conPO.year = conPO.year.sort((x, y) => y - x);
					let params = {
						areaList: [],
						cityList,
						provinceList,
						quotaList: this.$refs.indicatorTree.getCheckedKeys(),
						yearList: conPO.year,
					};
					const loading = this.$loading({
						lock: true,
						text: "下载中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					params.fileType = this.navIndex - 1;
					this.$api.industry
						.getEconomicDatabaseDownload(params)
						.then(ress => {
							if (ress.type == 'application/json') {
								let that = this
								let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
								reader.readAsText(ress)
								reader.onload = function (result) {
									let resData = JSON.parse(result.target.result);  // 解析对象成功，说明是json数据
									if (resData.code == '10001111') {
										that.$refs.toPayOrMember.openDialog()
										that.showPayBtn = false
										that.text = resData?.msg
									} else {
										that.$message.error(resData?.msg);
									}

								}
							} else {
								this.$utils.downloadFile(ress, "经济数据库.xlsx");
							}
						})
						.catch(msgs => {
							this.$message.error(msgs?.msg);
						})
						.finally(() => {
							loading.close();
						});
				} else {
					this.$api.industry
						.getEcoDataImgPermission().then(res => {
							if (this.chartData.length == 1) {
								this.ExportImg(document.getElementById("chart0"), "经济数据库图表", "png", "850");
							} else {
								this.ExportImg(document.getElementById("echarts-box"), "经济数据库图表", "png", "850");
							}
						}).catch(msg => {
							if (msg.code == '10001111') {
								this.$refs.toPayOrMember.openDialog()
								this.text = msg?.msg
							} else {
								this.$message.error(msg?.msg);
							}
						})
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		/*
		 * element  导出的元素
		 * filename 文件名
		 * ext      扩展文件名
		 */
		ExportImg(element, filename, ext, width) {
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			html2canvas(element, {
				useCORS: true,
				width: width,
				windowWidth: width,
			}).then(canvas => {
				if (navigator.msSaveBlob) {
					const blob = canvas.msToBlob(); // IE10+
					return navigator.msSaveBlob(blob, name);
				} else {
					loading.close();
					element.style.width = "100%";
					window.document.body.style.overflowX = "hidden";

					const imageurl = canvas.toDataURL("image/png");
					const aLink = document.createElement("a"); // 创建a标签
					aLink.style.display = "none";
					aLink.style.width = "100%";
					aLink.href = imageurl;
					aLink.download = `${filename}.${ext}`; // 下载文件名
					document.body.appendChild(aLink);
					aLink.click();
					document.body.removeChild(aLink); // 用完后移除元素
				}
			});
		},
		// 查找地区名称
		getAreaName(code) {
			let { areaList } = this;
			let isHave = false;
			let name = "";
			areaList.forEach(item => {
				if (item.code == code) {
					isHave = true;
					name = item.name;
				}
				if (!isHave) {
					if (code&&code.length>2&&item.code.indexOf(code.slice(0, 2)) != -1) {
						item.childrenList.forEach(key => {
							if (key.code == code) {
								isHave = true;
								name = key.name;
							}
							// if (!isHave) {
							//   key.childrenList.forEach((end) => {
							//     if (end.code == code) {
							//       name = end.name;
							//     }
							//   });
							// }
						});
					}
				}
			});
			return name;
		},
		// 2010年开始
		getYearList() {
			let date = new Date();
			let year = date.getFullYear();
			let yearList = [];
			let start = 2009;
			let length = year - start - 1;
			for (let i = 0; i < length; i++) {
				yearList.unshift((start += 1));
			}
			this.yearList = yearList;
		},
		// 点击切换查看状态
		clickNav(index) {
			if (this.navIndex == index) {
				return;
			}
			if (index != 4) {
				if (this.navIndex == 4) {
					this.$refs.selectArea.areaList = JSON.parse(JSON.stringify(this.$refs.selectchartArea.areaList))
					this.$refs.selectArea.selectList = JSON.parse(JSON.stringify(this.$refs.selectchartArea.selectList))
					this.conPO.year = this.chartPO.year
				} 
				let { conPO } = this;
				let quotaList = this.$refs.indicatorTree.getCheckedKeys();
				let selectList = this.$refs.selectArea.selectList;
				if (quotaList.length == 0 && conPO.year.length == 0 && selectList.length == 0) {
					if (index == 1) {
						this.getEconomicDatabaseByArea("init");
					} else if (index == 2) {
						this.getEconomicDatabaseByYear("init");
					} else {
						this.getEconomicDatabaseByQuota("init");
					}
				} else {
					this.handleQuery();
				}
			} else {
				this.$refs.selectchartArea.areaList = JSON.parse(JSON.stringify(this.$refs.selectArea.areaList))
				this.$refs.selectchartArea.selectList = JSON.parse(JSON.stringify(this.$refs.selectArea.selectList))

				this.chartPO.year = this.conPO.year
				if (this.$session.getUsers()) {
					this.getGdpAndGrowthRate();
				} else {
					this.$refs.loginModal.visible = true;
				}
			}

			this.navIndex = index;
		},
		// 处理表格需要的数据
		disposeData() {
			let { tableData, navIndex } = this;
			let endData = [];
			if (navIndex == 1) {
				for (let i = 0; i < tableData.length; i++) {
					let json = {
						year: tableData[i].year,
					};
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (tableData[i].children[j].children[k].quotaNumerical != "-") {
								tableData[i].children[j].children[k].quotaNumerical = +tableData[i].children[j].children[k].quotaNumerical.toFixed(2);
							}
							json[`indicator${j}${k}`] = tableData[i].children[j].children[k].quotaNumerical;
						}
					}
					endData.push(json);
				}
				this.areaTableData = endData;
				this.areaHeaderData = tableData[0].children;
			} else if (navIndex == 2) {
				for (let i = 0; i < tableData.length; i++) {
					let json = {
						area: this.getAreaName(tableData[i].area),
					};
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (tableData[i].children[j].children[k].quotaNumerical != "-") {
								tableData[i].children[j].children[k].quotaNumerical = +tableData[i].children[j].children[k].quotaNumerical.toFixed(2);
							}
							json[`indicator${j}${k}`] = tableData[i].children[j].children[k].quotaNumerical;
						}
					}
					endData.push(json);
				}
				this.yearTableData = endData;
				this.yearHeaderData = tableData[0].children;
			} else {
				for (let i = 0; i < tableData.length; i++) {
					let json = {
						year: tableData[i].year,
					};
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (tableData[i].children[j].children[k].quotaNumerical != "-") {
								tableData[i].children[j].children[k].quotaNumerical = +tableData[i].children[j].children[k].quotaNumerical.toFixed(2);
							}
							json[`indicator${j}${k}`] = tableData[i].children[j].children[k].quotaNumerical;
						}
					}
					endData.push(json);
				}
				this.indicatorTableData = endData;
				this.indicatorHeaderData = tableData[0].children;
			}
		},
		// 获取经济数据库-按地区
		getEconomicDatabaseByArea(init) {
			let { conPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			conPO.year = conPO.year.sort((x, y) => y - x);
			let params = {
				areaList: [],
				provinceList,
				cityList,
				quotaList: this.$refs.indicatorTree.getCheckedKeys(),
				yearList: conPO.year,
			};
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			if (init) {
				this.$api.industry
					.getEconomicDatabaseDefaultDataArea(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$api.industry
					.getEconomicDatabaseByArea(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 点击删除图标-按地区
		areaHandleDelete(label) {
			let { tableData } = this;
			if (tableData[0].children[0].children.length == 1) {
				// 只有一个指标数据时
				this.areaTableData = [];
				this.$message.success("已删除");
			} else {
				// 删除点击的属性
				for (let i = 0; i < tableData.length; i++) {
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (tableData[i].children[j].children[k].quota == label) {
								tableData[i].children[j].children.splice(k, 1);
							}
						}
					}
				}
				this.tableData = tableData;
				this.disposeData();
				this.$message.success("已删除");
			}
			// 同步删除搜索框指标和表格指标
			this.syncDel(label);
		},
		// 获取经济数据库-按年份
		getEconomicDatabaseByYear(init) {
			let { conPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			conPO.year = conPO.year.sort((x, y) => y - x);
			let params = {
				areaList: [],
				cityList,
				provinceList,
				quotaList: this.$refs.indicatorTree.getCheckedKeys(),
				yearList: conPO.year,
			};
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			if (init) {
				this.$api.industry
					.getEconomicDatabaseDefaultDataYear(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$api.industry
					.getEconomicDatabaseByYear(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 点击删除图标-按年份
		yearHandleDelete(label) {
			let { tableData } = this;
			if (tableData[0].children[0].children.length == 1) {
				// 只有一个指标数据时
				this.yearTableData = [];
				this.$message.success("已删除");
			} else {
				// 删除点击的属性
				for (let i = 0; i < tableData.length; i++) {
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (tableData[i].children[j].children[k].quota == label) {
								tableData[i].children[j].children.splice(k, 1);
							}
						}
					}
				}
				this.tableData = tableData;
				this.disposeData();
				this.$message.success("已删除");
			}
			// 同步删除搜索框指标和表格指标
			this.syncDel(label);
		},
		// 获取经济数据库-按指标
		getEconomicDatabaseByQuota(init) {
			let { conPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			conPO.year = conPO.year.sort((x, y) => y - x);
			let params = {
				areaList: [],
				cityList,
				provinceList,
				quotaList: this.$refs.indicatorTree.getCheckedKeys(),
				yearList: conPO.year,
			};
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			if (init) {
				this.$api.industry
					.getEconomicDatabaseDefaultDataQuota(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				this.$api.industry
					.getEconomicDatabaseByQuota(params)
					.then(res => {
						this.tableData = res.data;
						this.disposeData();
					})
					.catch(msg => {
						if (msg.code == '10001111') {
							this.$refs.toPayOrMember.openDialog()
							this.text = msg?.msg
						} else {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
		// 点击删除图标-按指标
		indicatorHandleDelete(label) {
			let { tableData } = this;
			if (tableData[0].children[0].children.length == 1) {
				// 只有一个指标数据时
				this.indicatorTableData = [];
				this.$message.success("已删除");
			} else {
				// 删除点击的属性
				for (let i = 0; i < tableData.length; i++) {
					for (let j = 0; j < tableData[i].children.length; j++) {
						for (let k = 0; k < tableData[i].children[j].children.length; k++) {
							if (this.getAreaName(tableData[i].children[j].children[k].area) == label) {
								tableData[i].children[j].children.splice(k, 1);
							}
						}
					}
				}
				this.tableData = tableData;
				this.disposeData();
				this.$message.success("已删除");
			}
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { conPO, navIndex, chartPO } = this;
				if (navIndex != 4) {
					if (conPO.year.length == 0) {
						this.$message.error("请选择年份");
						return;
					}
					let selectList = this.$refs.selectArea.selectList;

					if (selectList.length == 0) {
						this.$message.error("请选择地区");
						return;
					}
					let indicator = this.$refs.indicatorTree.getCheckedKeys();
					if (indicator.length == 0) {
						this.$message.error("请选择指标");
						return;
					}
					if (indicator.length > 5) {
						this.$message.error("最多只允许查询5个指标数据");
						return;
					}
					if (navIndex == 1) {
						this.getEconomicDatabaseByArea();
					} else if (navIndex == 2) {
						this.getEconomicDatabaseByYear();
					} else if (navIndex == 3) {
						this.getEconomicDatabaseByQuota();
					}
				} else {
					if (chartPO.year.length == 0) {
						this.$message.error("请选择年份");
						return;
					}
					// let selectList = this.$refs.selectchartArea.selectList;
					// if (selectList.length == 0) {
					//   this.$message.error("请选择地区");
					//   return;
					// }
					this.clearHander();
					let { quotaCode } = this.currentCheckData;
					if (quotaCode == 1) {
						this.getGdpAndGrowthRate();
					} else if (quotaCode == 2) {
						this.getIncomeAndDebt();
					} else if (quotaCode == 3) {
						this.getPopulationAndUrbanizationRate();
					} else if (quotaCode == 4) {
						this.getGrowthRateOfFixedAssetsInvestment();
					} else {
						this.getValueAddedAndProportionOfGdp();
					}
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 省市区获取
		getAreaList(chart) {
			this.$api.industry
				.getAreaList()
				.then(res => {
					let areaList = res.data;
					areaList.forEach(item => {
						item.childrenList.forEach(key => {
							delete key.childrenList;
						});
					});
					for (let i = 0; i < areaList.length; i++) {
						areaList[i].type = "省";
						for (let j = 0; j < areaList[i].childrenList.length; j++) {
							areaList[i].childrenList[j].type = "市";
						}
					}
					this.areaList = areaList;
					this.$refs.selectArea.areaList = areaList;
					if (chart) {
						this.$refs.selectchartArea.areaList = [
							...areaList.map(item => {
								return Object.assign({}, item);
							}),
						];
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击重置
		resetQuery() {
			if (this.navIndex != 4) {
				this.conPO = {
					year: [],
					area: [],
				};
				// 清空指标
				if (this.$refs.indicatorTree.getCheckedKeys().length != 0) {
					let node = this.$refs.indicatorTree.getNode(this.indicatorList[0].quotaCode).parent;
					node.childNodes.forEach(val => {
						val.checked = false;
						if (val.childNodes && val.childNodes.length > 0) {
							val.childNodes.forEach(value => {
								value.checked = false;
								if (value.childNodes && value.childNodes.length > 0) {
									value.childNodes.forEach(value_ba => {
										value_ba.checked = false;
									});
								}
							});
						}
					});
				}
				this.selectAreaKey = new Date().getTime();
				this.getAreaList();
			} else {
				// this.chartPO.year = [this.yearList[0]];
				this.selectchartAreaKey = new Date().getTime();
				this.getAreaList("chart");
				this.defaultkeys = [1];
				this.$refs.tree.setCheckedKeys([]);
				this.currentCheckData = { quotaCode: "1" };
			}
		},
		// 同步删除搜索框指标和表格指标
		syncDel(label) {
			let quotaList = this.indicatorList;
			let selectKeys = this.$refs.indicatorTree.getCheckedKeys();
			let selectKeys2 = selectKeys;
			for (let i = 0; i < quotaList.length; i++) {
				for (let j = 0; j < quotaList[i].childrenList.length; j++) {
					if (quotaList[i].childrenList[j].quotaName == label) {
						for (let k = 0; k < selectKeys.length; k++) {
							if (selectKeys[k] == quotaList[i].childrenList[j].quotaCode) {
								selectKeys2.splice(k, 1);
							}
						}
					}
				}
			}
			this.$refs.indicatorTree.setCheckedKeys(selectKeys2);
		},
		// 点击时设置单选
		treeNodeClick(data) {
			// 如果记录的值和当前选中的值的quotaCode一致则进行对应的置空处理
			// if (this.currentCheckData.quotaCode === data.quotaCode) {
			//   this.currentCheckData = {};
			//   this.$refs.tree.setCheckedKeys([]);
			// } else {
			// 否则，覆盖原先的值
			this.currentCheckData = data;
			this.$refs.tree.setCheckedKeys([data.quotaCode]);
			// }
			// let quotaCode = data.quotaCode;
			// this.clearHander();
			this.defaultkeys = [];
			// if (quotaCode == 1) {
			//   this.getGdpAndGrowthRate();
			// } else if (quotaCode == 2) {
			//   this.getIncomeAndDebt();
			// } else if (quotaCode == 3) {
			//   this.getPopulationAndUrbanizationRate();
			// } else if (quotaCode == 4) {
			//   this.getGrowthRateOfFixedAssetsInvestment();
			// } else {
			//   this.getValueAddedAndProportionOfGdp();
			// }
		},
		clearHander() {
			for (let index = 0; index < this.chartData.length; index++) {
				let chartDom = document.getElementById(`chart${index}`);
				let myChart = echarts.init(chartDom);
				myChart.clear();
			}
		},
		// echarts初始化
		initChart(id, data, legend, type) {
			let chartDom = document.getElementById(id);
			let myChart = echarts.init(chartDom);
			let newOption = JSON.parse(JSON.stringify(option));
			newOption.title.text = data.title;
			newOption.xAxis.data = data.name;
			if (legend) {
				newOption.legend.data = legend;
				newOption.series[0].name = legend[0];
				newOption.series[1].name = legend[1];
			}
			if (type == 3) {
				newOption.yAxis[0].name = "万人";
			}
			newOption.series[0].data = data.data;
			newOption.series[1].data = data.rate;
			newOption && myChart.setOption(newOption);
			window.addEventListener("resize", function () {
				myChart.resize();
			});
		},
		initCurveChart(id, data) {
			let chartDom = document.getElementById(id);
			let myChart = echarts.init(chartDom);
			let option = JSON.parse(JSON.stringify(curveOption));
			option.title.text = data.title;
			option.xAxis.data = data.name;
			option.series[0].data = data.data;
			option && myChart.setOption(option);
			window.addEventListener("resize", function () {
				myChart.resize();
			});
		},
		// 获取GDP及增速（%）图表数据
		getGdpAndGrowthRate() {
			let { chartPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectchartArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			this.$api.industry
				.getGdpAndGrowthRate({
					yearList: chartPO.year,
					provinceList,
					cityList,
				})
				.then(res => {
					let data = res.data;
					this.chartData = data;
					for (let index = 0; index < data.length; index++) {
						this.$nextTick(() => {
							this.initChart(`chart${index}`, data[index]);
						});
					}
				})
				.catch(msg => {
					if (msg.code == '10001111') {
						this.$refs.toPayOrMember.openDialog()
						this.text = msg?.msg
					} else {
						this.$message.error(msg?.msg);
					}
				})
		},
		// 获取一般公共预算收入及债务负担水平图表数据
		getIncomeAndDebt() {
			let { chartPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectchartArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getIncomeAndDebt({
					yearList: chartPO.year,
					provinceList,
					cityList,
				})
				.then(res => {
					let data = res.data;
					this.chartData = data;
					for (let index = 0; index < data.length; index++) {
						this.$nextTick(() => {
							this.initChart(`chart${index}`, data[index], ["一般公共预算收入（亿元）", "负债负担（%）"]);
						});
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取人口数量及城镇化率图表数据
		getPopulationAndUrbanizationRate() {
			let { chartPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectchartArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getPopulationAndUrbanizationRate({
					yearList: chartPO.year,
					provinceList,
					cityList,
				})
				.then(res => {
					let data = res.data;
					this.chartData = data;
					for (let index = 0; index < data.length; index++) {
						this.$nextTick(() => {
							this.initChart(`chart${index}`, data[index], ["常住人口（万人）", "城镇化（%）"], 3);
						});
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取固定资产投资增速图表数据
		getGrowthRateOfFixedAssetsInvestment() {
			let { chartPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectchartArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getGrowthRateOfFixedAssetsInvestment({
					yearList: chartPO.year,
					provinceList,
					cityList,
				})
				.then(res => {
					let data = res.data;
					this.chartData = data;
					for (let index = 0; index < data.length; index++) {
						this.$nextTick(() => {
							this.initCurveChart(`chart${index}`, data[index]);
						});
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 获取建筑业增加值及GDP占比图表数据
		getValueAddedAndProportionOfGdp() {
			let { chartPO } = this;
			let provinceList = [];
			let cityList = [];
			let selectList = this.$refs.selectchartArea.selectList;
			if (selectList.length != 0) {
				selectList.forEach(item => {
					if (item.type == "省") {
						provinceList.push(item.code);
					} else if (item.type == "市") {
						cityList.push(item.code);
					}
				});
			}
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getValueAddedAndProportionOfGdp({
					yearList: chartPO.year,
					provinceList,
					cityList,
				})
				.then(res => {
					let data = res.data;
					this.chartData = data;
					for (let index = 0; index < data.length; index++) {
						this.$nextTick(() => {
							this.initChart(`chart${index}`, data[index], ["建筑业增加值（亿元）", "占GDP比重（%）"]);
						});
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
	},
};
</script>

<style lang="less" scope>
@import "./index.less";
</style>
