<template>
  <div class="select-container">
    <div :class="selectList.length == 0 ? '' : 'select-list'">
      <div
        class="select-area"
        :class="open ? 'select-area-open' : ''"
        @click.stop="openSelect"
      >
        <div class="placeholder" v-show="selectList.length == 0">请选择</div>
        <div class="select-box" v-if="selectList.length != 0">
          <div class="select-name">
            <el-tooltip
              effect="light"
              :content="selectList[0].name"
              placement="top-start"
            >
              <span class="fullnames text-ellipsis">{{
                selectList[0].name
              }}</span>
            </el-tooltip>
            <!-- <i class="el-icon-error" @click.stop="delSelect(selectList[0])"></i> -->
          </div>
          <div class="select-num" v-show="selectList.length > 1">
            + {{ selectList.length - 1 }}
          </div>
        </div>
        <i class="el-icon-arrow-down"></i>
        <i class="el-icon-circle-close" @click.stop="delAll"></i>
      </div>
    </div>
    <div
      class="pull-box animated fadeInDownBig"
      :style="{ left: !isCounty ? '' : '-150px' }"
      v-show="open"
      @mouseenter="enterPull"
      @mouseleave="leavePull"
    >
      <div class="item-box">
        <div class="empty" v-show="areaList.length == 0">暂无数据</div>
        <div class="select-item" v-show="areaList.length != 0 && !all">
          <div @click.stop>
            <el-checkbox
              v-model="proChecked"
              @change="allProvince"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="proChecked ? 'label-name-check' : ''"
            >
              全选
            </div>
            <div style="width: 6px"></div>
          </div>
        </div>
        <div
          class="select-item"
          :class="areaIndex == index ? 'select-item-selcet' : ''"
          v-for="(item, index) in areaList"
          :key="index"
          @click="selectProvince(item, index)"
        >
          <div @click.stop>
            <el-checkbox
              v-model="item.checked"
              @change="changeProvince(item)"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="item.checked ? 'label-name-check' : ''"
            >
              {{ item.name }}
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="item-box" v-show="isCity">
        <div class="empty" v-show="cityList.length == 0">暂无数据</div>
        <div class="select-item" v-show="cityList.length != 0">
          <div @click.stop>
            <el-checkbox v-model="cityChecked" @change="allCity"></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="cityChecked ? 'label-name-check' : ''"
            >
              全选
            </div>
            <div style="width: 6px"></div>
          </div>
        </div>
        <div
          class="select-item"
          :class="cityIndex == i ? 'select-item-selcet' : ''"
          v-for="(item, i) in cityList"
          :key="item.code"
          @click="selectCity(item, i)"
        >
          <div @click.stop>
            <el-checkbox
              v-model="item.checked"
              @change="changeCity(item)"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="item.checked ? 'label-name-check' : ''"
            >
              {{ item.name }}
            </div>
            <i class="el-icon-arrow-right" v-show="!all"></i>
          </div>
        </div>
      </div>
      <div class="item-box" v-show="isCounty">
        <div class="empty" v-show="countyList.length == 0">暂无数据</div>
        <div class="select-item" v-show="countyList.length != 0">
          <div @click.stop>
            <el-checkbox
              v-model="countyChecked"
              @change="allCounty"
            ></el-checkbox>
          </div>
          <div class="label-box">
            <div
              class="label-name"
              :class="countyChecked ? 'label-name-check' : ''"
            >
              全选
            </div>
            <div style="width: 6px"></div>
          </div>
        </div>
        <div class="select-item" v-for="item in countyList" :key="item.code">
          <el-checkbox
            v-model="item.checked"
            @change="changeCounty(item)"
          ></el-checkbox>
          <div class="label-box">
            <div
              class="label-name"
              :class="item.checked ? 'label-name-check' : ''"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    all: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false, // 下拉框是否显示
      areaList: [], // 所有省市区数据
      cityList: [], // 市级数据
      countyList: [], // 区县数据
      isCity: false,
      isCounty: false,
      areaIndex: null,
      cityIndex: null,
      selectList: [],
      proChecked: false,
      cityChecked: false,
      countyChecked: false
    };
  },
  mounted() {
    // 监听click事件
    window.addEventListener("click", this.eventListener);
  },
  methods: {
    //  点击展开收起下拉框
    openSelect() {
      this.open = !this.open;
    },
    // 关闭下拉
    eventListener() {
      this.open = false;
    },
    // 鼠标移入下拉
    enterPull() {
      // 移除监听click事件
      window.removeEventListener("click", this.eventListener);
    },
    // 鼠标移除下拉
    leavePull() {
      // 监听click事件
      window.addEventListener("click", this.eventListener);
    },
    // 点击省
    selectProvince(item, index) {
      this.areaIndex = index;
      this.cityIndex = null;
      this.isCity = true;
      let cityList = item.childrenList;
      this.cityList = cityList;
      this.isCounty = false;
      this.countyList = [];
      let cityChecked = true;
      cityList.forEach(key => {
        if (!key.checked) {
          cityChecked = false;
        }
      });
      this.cityChecked = cityChecked;
    },
    // 点击市
    selectCity(item, index) {
      if (this.all) {
        return;
      }
      this.cityIndex = index;
      this.areaIndex = null;
      this.isCounty = true;
      let countyList = item.childrenList;
      this.countyList = countyList;
      let countyChecked = true;
      countyList.forEach(key => {
        if (!key.checked) {
          countyChecked = false;
        }
      });
      this.countyChecked = countyChecked;
    },
    // 勾选省
    changeProvince(data) {
      this.areaIndex = null;
      this.cityIndex = null;
      let { areaList, selectList } = this;
      if (data.checked) {
        // 点击勾选
        areaList.forEach(item => {
          if (item.checked) {
            selectList = [...selectList, ...[item]];
          }
        });
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);
        this.selectList = endList;
      } else {
        // 去除勾选
        let endList = [];
        selectList.forEach(key => {
          if (key.code != data.code) {
            endList.push(key);
          }
        });
        this.selectList = endList;
      }
      let proChecked = true;
      areaList.forEach(key => {
        if (!key.checked) {
          proChecked = false;
        }
      });
      this.proChecked = proChecked;
    },
    // 勾选市
    changeCity(data) {
      this.areaIndex = null;
      this.cityIndex = null;
      let { cityList, selectList } = this;
      if (data.checked) {
        // 点击勾选
        cityList.forEach(item => {
          if (item.checked) {
            selectList = [...selectList, ...[item]];
          }
        });
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);

        this.selectList = endList;
      } else {
        // 去除勾选
        let endList = [];
        selectList.forEach(key => {
          if (key.code != data.code) {
            endList.push(key);
          }
        });
        this.selectList = endList;
      }

      let cityChecked = true;
      cityList.forEach(key => {
        if (!key.checked) {
          cityChecked = false;
        }
      });
      this.cityChecked = cityChecked;
    },
    // 勾选区县
    changeCounty(data) {
      this.areaIndex = null;
      this.cityIndex = null;
      let { countyList, selectList } = this;
      if (data.checked) {
        // 点击勾选
        countyList.forEach(item => {
          if (item.checked) {
            selectList = [...selectList, ...[item]];
          }
        });
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);

        this.selectList = endList;
      } else {
        // 去除勾选
        let endList = [];
        selectList.forEach(key => {
          if (key.code != data.code) {
            endList.push(key);
          }
        });
        this.selectList = endList;
      }
      let countyChecked = true;
      countyList.forEach(key => {
        if (!key.checked) {
          countyChecked = false;
        }
      });
      this.countyChecked = countyChecked;
    },
    // 省份全选
    allProvince() {
      let { proChecked, areaList, selectList } = this;
      if (proChecked) {
        areaList.forEach(item => {
          item.checked = true;
          selectList = [...selectList, ...[item]];
        });
        this.areaList = areaList;
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);
        this.selectList = endList;
      } else {
        areaList.forEach(item => {
          item.checked = false;
        });
        this.areaList = areaList;
        // （删除省级数据）比较 areaList和selectList取出不同的值
        let selectedCodes = [];
        areaList.forEach(({ code }) => {
          selectedCodes.push(code);
        });
        let selectResult = selectList.filter(
          ({ code }) => !selectedCodes.includes(code)
        );
        this.selectList = selectResult;
      }
    },
    // 市级全选
    allCity() {
      let { cityChecked, cityList, selectList } = this;
      if (cityChecked) {
        cityList.forEach(item => {
          item.checked = true;
          selectList = [...selectList, ...[item]];
        });
        this.cityList = cityList;
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);
        this.selectList = endList;
      } else {
        cityList.forEach(item => {
          item.checked = false;
        });
        this.cityList = cityList;
        // （删除市级数据）比较 cityList和selectList取出不同的值
        let selectedCodes = [];
        cityList.forEach(({ code }) => {
          selectedCodes.push(code);
        });
        let selectResult = selectList.filter(
          ({ code }) => !selectedCodes.includes(code)
        );
        this.selectList = selectResult;
      }
    },
    // 区县全选
    allCounty() {
      let { countyChecked, countyList, selectList } = this;
      if (countyChecked) {
        countyList.forEach(item => {
          item.checked = true;
          selectList = [...selectList, ...[item]];
        });
        this.countyList = countyList;
        // 去重
        let obj = {};
        let endList = selectList.reduce((item, next) => {
          obj[next.code] ? "" : (obj[next.code] = true && item.push(next));
          return item;
        }, []);
        this.selectList = endList;
      } else {
        countyList.forEach(item => {
          item.checked = false;
        });
        this.countyList = countyList;
        // （删除市级数据）比较 countyList和selectList取出不同的值
        let selectedCodes = [];
        countyList.forEach(({ code }) => {
          selectedCodes.push(code);
        });
        let selectResult = selectList.filter(
          ({ code }) => !selectedCodes.includes(code)
        );
        this.selectList = selectResult;
      }
    },
    // 清空已选内容
    delAll() {
      this.$parent.selectchartAreaKey = new Date().getTime();
      this.$parent.getAreaList("chart");
    }
  },
  destroyed() {
    // 移除监听click事件
    window.removeEventListener("click", this.eventListener);
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
